import type { ISbNode } from "@storyblok/js"
import cloneDeep from "clone-deep"
import type { RichtextStoryblok } from "~/storyblok-component-types"

type NodeSchema = { (node: ISbNode): object; }

export const renderRTF = (richtext?: RichtextStoryblok | string) => {
  const img = useImage()

  const image: NodeSchema = (node: ISbNode) => ({
    singleTag: [
      {
        tag: "img",
        attrs: {
          src: img(
            node.attrs.src,
            { format: "webp,png", height: 32 },
            { densities: "x1 x2", provider: node.attrs.src.includes("a.storyblok") ? "storyblok" : undefined },
          ),
          alt: node.attrs.alt ?? "",
          ...(node.attrs.title && { title: node.attrs.title }),
        },
      },
    ],
  })

  const schema = cloneDeep(RichTextSchema)
  schema.nodes.image = image

  return computed(() => (typeof richtext === "string" ? richtext : renderRichText(richtext, { schema })))
}
