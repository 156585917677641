<template>
  <figure v-if="caption" class="flex flex-col place-items-center gap-2">
    <nuxt-picture
      v-if="blok.filename"
      ref="pictureEl"
      :src="blok.filename"
      format="webp,avif"
      densities="x1 x2"
      :provider="blok.filename.includes('a.storyblok') ? 'storyblok' : undefined"
      :width="width"
      :height="height"
      :fit="fitInside ? (blok.filename.includes('a.storyblok') ? 'in' : 'inside') : undefined"
      :loading="loading"
      class="isolate"
      :class="containerClass"
      :img-attrs="{
        ...$attrs,
        class: [
          fitInside ? 'object-contain before:bg-contain' : 'object-cover before:bg-cover',
          'transition-[filter] duration-75 before:inline-block before:size-full before:bg-center before:bg-no-repeat before:blur before:transition-opacity before:duration-75 before:content-[\'\'] before:[background-image:var(--placeholder)] [&.loaded]:!blur-0 [&.loaded]:before:opacity-0 [&.progressive]:blur',
          $attrs.class,
        ],
        alt: decorative ? '' : blok.alt,
        title: $attrs.title || blok.title,
        style: `--placeholder: url('${placeholderData}')`,
      }"
      :sizes="
        !width || isNaN(+width)
          ? '390px tablet:640px desktop:1024px'
          : `${Math.min(+width, 390)}px tablet:${Math.min(+width, 640)}px desktop:${width}px`
      "
      :preload="preload"
    />
    <figcaption v-if="caption" class="block w-auto text-center text-text-accent empty:hidden">
      {{ caption }}
    </figcaption>
  </figure>
  <nuxt-picture
    v-else
    ref="pictureEl"
    :src="blok.filename"
    format="webp,avif"
    densities="x1 x2"
    :provider="blok.filename.includes('a.storyblok') ? 'storyblok' : undefined"
    :width="width"
    :height="height"
    :fit="fitInside ? (blok.filename.includes('a.storyblok') ? 'in' : 'inside') : undefined"
    :loading="loading"
    :class="containerClass"
    :img-attrs="{
      ...$attrs,
      class: [
        fitInside ? 'object-contain before:bg-contain' : 'object-cover before:bg-cover',
        'transition-[filter] duration-75 before:hidden before:size-full before:bg-center before:bg-no-repeat before:blur before:transition-opacity before:duration-75 before:content-[\'\'] before:[background-image:var(--placeholder)] [&.loaded]:!blur-0 [&.loaded]:before:opacity-0 [&.progressive]:blur [&.progressive]:before:inline-block',
        $attrs.class,
      ],
      alt: decorative ? '' : blok.alt,
      title: $attrs.title || blok.title,
      style: [`--placeholder: url('${placeholderData}')`, $attrs.style],
    }"
    :sizes="
      !width || isNaN(+width)
        ? '390px tablet:640px desktop:1024px'
        : `${Math.min(+width, 390)}px tablet:${Math.min(+width, 640)}px desktop:${width}px`
    "
    :preload="preload"
  />
</template>

<script setup lang="ts">
import type { AssetStoryblok } from "~/storyblok-component-types"

defineOptions({ inheritAttrs: false })

const img = useImage()
const props = withDefaults(
  defineProps<{
    blok: AssetStoryblok
    width?: string | number
    height?: string | number
    fitInside?: boolean
    decorative?: boolean
    caption?: string
    loading?: "lazy" | "eager"
    containerClass?: string | Record<string, boolean> | Array<string | Record<string, boolean>>
    preload?: boolean | { fetchPriority: "auto" | "high" | "low"; }
  }>(),
  {
    loading: "lazy",
    class: "",
    containerClass: "",
  },
)

const pictureEl = ref()

onMounted(() => {
  // if the image doesn't load quickly, use progressive loading
  const element = pictureEl.value.$el as HTMLPictureElement
  const img = element.querySelector("img")

  if (img) {
    if (img.complete) img.classList.add("loaded")
    else {
      img.classList.add("progressive")
      img.addEventListener("load", () => {
        img.classList.add("loaded")
      })
    }
  }
})

const placeholderData = computed(() => {
  if (props.width && props.height && +props.width && +props.height && !Number.isNaN(props.width) && !Number.isNaN(props.height)) {
    return img(
      props.blok.filename,
      {
        format: "webp",
        width: Math.max(1, Math.floor(+props.width * 0.05)),
        height: Math.max(1, Math.floor(+props.height * 0.05)),
        fit: props.fitInside ? props.blok.filename.includes("a.storyblok") ? "in" : "inside" : undefined,
      },
      {
        provider: props.blok.filename.includes("a.storyblok") ? "storyblok" : undefined,
        densities: "x1 x2",
      },
    )
  } else return ""
})
</script>
