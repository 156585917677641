import revive_payload_client_sRS3ERcGsB from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_6azegr3gslcihxez3hw7jaslaq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ezZD4dWUFH from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_6azegr3gslcihxez3hw7jaslaq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_SMQx0baZpt from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_6azegr3gslcihxez3hw7jaslaq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_9fVj64cum2 from "/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.4_rollup@4.17.2_vite@5.2.11_@types+node@20.12.12_sass@1._2klaeafmq3mxvuougjvysto7ha/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_sx2YWsi8y7 from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_6azegr3gslcihxez3hw7jaslaq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_ObA44JBnDA from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_6azegr3gslcihxez3hw7jaslaq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_povnCbMrZw from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_6azegr3gslcihxez3hw7jaslaq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import titles_pC8PRrdGQS from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.4_rollup@4.17.2_vite@5.2.11_@types+node@20.12._xnlf573d4dl6ajriumfrgx3lkq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_bKS62qEWyC from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.4_rollup@4.17.2_vite@5.2.11_@types+node@20.12.12_sass_ncvsjzas4tphroifx4gsftiavm/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_g2Qi47UrRA from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.4_rollup@4.17.2_vite@5.2.11_@types+node@20.12.12_sass_ncvsjzas4tphroifx4gsftiavm/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_SfZdMAwxX0 from "/app/node_modules/.pnpm/@storyblok+nuxt@6.0.12/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import chunk_reload_client_hueoqPvSsC from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_6azegr3gslcihxez3hw7jaslaq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import defaults_wkxsYb5YBl from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.4_rollup@4.17.2_vite@5.2.11_@types+node@20.12._xnlf573d4dl6ajriumfrgx3lkq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_sRS3ERcGsB,
  unhead_ezZD4dWUFH,
  router_SMQx0baZpt,
  _0_siteConfig_9fVj64cum2,
  payload_client_sx2YWsi8y7,
  check_outdated_build_client_ObA44JBnDA,
  components_plugin_KR1HBZs4kY,
  prefetch_client_povnCbMrZw,
  plugin_wy0B721ODc,
  titles_pC8PRrdGQS,
  siteConfig_bKS62qEWyC,
  inferSeoMetaPlugin_g2Qi47UrRA,
  plugin_SfZdMAwxX0,
  chunk_reload_client_hueoqPvSsC,
  defaults_wkxsYb5YBl
]