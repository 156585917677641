import type { ISbStoriesParams } from "@storyblok/vue"

export const useSbOptions = async (storyblokEnv: "published" | "draft" = "published") => {
  const storyblokToken = useRuntimeConfig().public.storyblok.accessToken
  const cache = await useFetch<{ version: number; source: string; }>("/api/storyblok-cache")

  const apiOptions: ISbStoriesParams = {
    version: storyblokEnv,
    resolve_links: "url",
    resolve_relations: [
      "page.header",
      "page.footer",
      "BlogArticle.header",
      "BlogArticle.footer",
      "BlogArticleOverview.header",
      "BlogArticleOverview.footer",
      "slideshow.header",
      "slideshow.footer",
      "pageWithTOC.header",
      "pageWithTOC.footer",
      "cta-newsletter.successPage",
      "form-call-back.successPage",
      "form-tinnitus.successPage",
    ],
    token: storyblokToken,
    cv: cache.data.value?.version,
  }

  return apiOptions
}
