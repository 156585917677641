import type { ISbStoriesParams } from "@storyblok/vue"
import type { StoryblokAnyStory } from "./utils/types"
import type { FooterStoryblok, HeaderStoryblok } from "~/storyblok-component-types"

export const storyKey = Symbol() as InjectionKey<StoryblokAnyStory>
export const isEditorKey = Symbol() as InjectionKey<boolean>
export const environmentKey = Symbol() as InjectionKey<"production" | "staging" | "development">
export const storyblokOptionsKey = Symbol() as InjectionKey<ISbStoriesParams>

export const headerKey = Symbol() as InjectionKey<{
  headerBlok: Ref<HeaderStoryblok | undefined>
  updateHeaderBlok: (blok: HeaderStoryblok) => void
}>
export const footerKey = Symbol() as InjectionKey<{
  footerBlok: Ref<FooterStoryblok | undefined>
  updateFooterBlok: (blok: FooterStoryblok) => void
}>

export const storyblokEnvKey = Symbol() as InjectionKey<"published" | "draft">

export const openDialogKey = Symbol() as InjectionKey<(dialogUrl: string, loadOnly?: boolean) => void>
