import type { RouterConfig } from "@nuxt/schema"

export default <RouterConfig>{
  scrollBehavior(to, from, savedPosition) {
    // hash scroll on page load doesn't work well, so it is handeled manually in app.vue
    if (from.matched.length) {
      if (savedPosition) {
        return savedPosition
      } else if (to.hash) {
        return {
          el: to.hash,
          top: 128,
        }
      } else {
        return { top: 0 }
      }
    }
  },
}
