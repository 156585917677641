import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as datenschutz8Idu2tRmArMeta } from "/app/pages/datenschutz.vue?macro=true";
import { default as impressumLDkFRkkSAmMeta } from "/app/pages/impressum.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612ZJg3ack67TMeta } from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_6azegr3gslcihxez3hw7jaslaq/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612ZJg3ack67T } from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_6azegr3gslcihxez3hw7jaslaq/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91_46_46_46slug_93ILszah2VNpMeta?.name ?? "slug",
    path: _91_46_46_46slug_93ILszah2VNpMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    alias: _91_46_46_46slug_93ILszah2VNpMeta?.alias || [],
    redirect: _91_46_46_46slug_93ILszah2VNpMeta?.redirect,
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexgWaMlevJ7RMeta?.name ?? "blog",
    path: indexgWaMlevJ7RMeta?.path ?? "/blog",
    meta: indexgWaMlevJ7RMeta || {},
    alias: indexgWaMlevJ7RMeta?.alias || [],
    redirect: indexgWaMlevJ7RMeta?.redirect,
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: datenschutz8Idu2tRmArMeta?.name ?? "datenschutz",
    path: datenschutz8Idu2tRmArMeta?.path ?? "/datenschutz",
    meta: datenschutz8Idu2tRmArMeta || {},
    alias: datenschutz8Idu2tRmArMeta?.alias || [],
    redirect: datenschutz8Idu2tRmArMeta?.redirect,
    component: () => import("/app/pages/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: impressumLDkFRkkSAmMeta?.name ?? "impressum",
    path: impressumLDkFRkkSAmMeta?.path ?? "/impressum",
    meta: impressumLDkFRkkSAmMeta || {},
    alias: impressumLDkFRkkSAmMeta?.alias || [],
    redirect: impressumLDkFRkkSAmMeta?.redirect,
    component: () => import("/app/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612ZJg3ack67TMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612ZJg3ack67TMeta?.path ?? "/startseite/terzo%C2%AETinnitustherapie",
    meta: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612ZJg3ack67TMeta || {},
    alias: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612ZJg3ack67TMeta?.alias || [],
    redirect: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612ZJg3ack67TMeta?.redirect,
    component: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612ZJg3ack67T
  },
  {
    name: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612ZJg3ack67TMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612ZJg3ack67TMeta?.path ?? "/startseite/terzo-tinnitustherapie",
    meta: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612ZJg3ack67TMeta || {},
    alias: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612ZJg3ack67TMeta?.alias || [],
    redirect: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612ZJg3ack67TMeta?.redirect,
    component: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612ZJg3ack67T
  }
]