<template>
  <footer v-editable="blok" class="border-t-2 border-blue-lighter">
    <template v-if="blok?.body?.length">
      <section aria-label="Unterseiten">
        <storyblok-component v-for="childBlok in blok.body" :key="childBlok._uid" :blok="childBlok" />
      </section>
      <hr class="border-t-2 border-blue-lighter" />
    </template>

    <template v-if="blok?.socmediaList?.length">
      <section aria-label="Social Media" class="container mx-auto flex flex-row items-center justify-center gap-12 px-4 py-8">
        <sb-link
          v-for="childBlok in blok.socmediaList"
          :key="childBlok._uid"
          v-editable="childBlok"
          :link="childBlok.link"
          target="_blank"
          rel="noopener"
          :title="childBlok.name"
          class="rounded-full bg-secondary p-2.5 text-3xl text-back-accent outline outline-2 outline-offset-2 outline-transparent transition-colors duration-[50ms] hover:outline-secondary focus:outline-secondary active:bg-secondary-accent active:outline-secondary-accent"
        >
          <nuxt-icon :name="childBlok.icon.toString()" class="[&>svg]:!mb-0" />
        </sb-link>
      </section>
      <hr class="border-t-2 border-blue-lighter" />
    </template>

    <section aria-label="Rechtliches" class="px-4 py-16">
      <div class="container mx-auto grid grid-cols-2 grid-rows-2 gap-8 tablet:grid-cols-4 tablet:gap-2">
        <audoora-logo :nh="blok?.isNh" variant="text-motto" class="col-span-2 text-3xl" />

        <div v-if="visionText" class="isolate -order-1 col-span-2 row-span-2 my-4 grid place-items-center tablet:-order-none">
          <div
            class="relative max-w-xs rounded-double border-2 border-text bg-back-accent p-6 font-semibold leading-tight before:absolute before:-bottom-3 before:-right-3 before:-z-10 before:block before:size-full before:rounded-double before:border-2 before:border-text before:bg-[url('/pattern.svg')] before:bg-right-bottom before:bg-repeat before:content-[''] before:dark:bg-[url('/pattern-dark.svg')]"
          >
            <audoora-logo :nh="blok?.isNh" variant="icon" class="absolute right-4 top-5 text-xl" />
            <div class="inline-images prose prose-base leading-snug dark:prose-invert prose-headings:font-bold" v-html="visionText" />
          </div>
        </div>

        <atoms-link-list class="col-span-2 tablet:col-span-1">
          <li>
            <nuxt-link to="/ueber-uns"> Über uns </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/impressum"> Impressum </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/datenschutz"> Datenschutz­erklärung </nuxt-link>
          </li>
        </atoms-link-list>
        <atoms-link-list class="col-span-2 tablet:col-span-1">
          <li>
            <button id="hs_show_banner_button" class="-my-0.5 text-start leading-none" type="button" @click="hubspotBanner('showBanner')">
              Cookie-Einstellungen
            </button>
          </li>
          <li v-for="childBlok in blok?.legalLinks || []" :key="childBlok._uid">
            <storyblok-component :blok="childBlok" />
          </li>
        </atoms-link-list>
      </div>
    </section>
    <hr class="border-t-2 border-transparent" />

    <div class="bg-back px-4 text-text">
      <div class="container mx-auto flex flex-col-reverse items-center justify-center gap-x-4 gap-y-8 py-2 tablet:flex-row">
        <section aria-label="Copyright">
          <span>© {{ currrentYear }} audoora GmbH</span>
          <span class="hidden tablet:inline">&MediumSpace;|&MediumSpace;</span>
          <br class="inline tablet:hidden" />
          <span>Entwickelt vom Netzwerk Hörgesundheit</span>
        </section>
        <component
          :is="listItem.link && renderSbLink(listItem.link) ? sbLink : 'span'"
          v-for="listItem in blok?.afterCopyright"
          :key="listItem._uid"
          v-editable="blok"
          :link="listItem.link"
          class="ml-4 first-of-type:ml-auto"
          :title="listItem.name"
        >
          <sb-image :blok="listItem.image" height="60" width="205" fit-inside class="w-auto" />
        </component>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import type { FooterStoryblok } from "~/storyblok-component-types"
import sbLink from "~/components/sb-link.vue"

const props = defineProps<{ blok?: FooterStoryblok; }>()
const visionText = renderRTF(props.blok?.visionCard)

const currrentYear = new Date().getFullYear()
</script>
