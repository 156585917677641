<template>
  <header
    class="group container mx-auto mb-4 h-24 max-w-[1440px] px-4 pt-4 transition-spacing aria-expanded:p-0"
    :aria-expanded="mobileOpen"
  >
    <div
      class="absolute left-1/2 top-0 -z-10 h-12 w-dvw -translate-x-1/2 bg-white/20 backdrop-blur dark:bg-blue-dark/20"
      style="mask-image: linear-gradient(to bottom, black 1rem, transparent)"
    />
    <nav
      class="container mx-auto flex w-full max-w-[1440px] flex-row items-center gap-4 rounded-double border-2 border-x-0 shadow-[0_0_37px_37px] transition-[padding,border-radius,border-color] group-aria-expanded:rounded-[0] group-aria-expanded:p-8 p-4"
      :class="
        blok?.isNh
          ? 'border-white bg-white  text-blue-dark shadow-blue-dark/10 group-aria-expanded:border-green-dark group-aria-expanded:border-b-green-dark group-aria-expanded:bg-green-dark'
          : 'border-blue-dark bg-blue-dark text-white shadow-white/10 group-aria-expanded:border-blue-dark group-aria-expanded:border-b-blue'
      "
    >
      <nuxt-link to="/" class="mr-auto mt-1 px-2 text-2xl leading-none">
        <audoora-logo :nh="blok?.isNh" variant="text" />
      </nuxt-link>
      <template v-if="blok?.links?.length">
        <ol
          :hidden="mobileScreen && blok.links.length > 1"
          class="header-button-list-desktop flex-row gap-4"
          :class="{ '!hidden desktop-header:!flex': blok.links.length > 1 }"
          role="menubar"
          aria-orientation="horizontal"
        >
          <li v-for="buttonBlok in blok?.links || []" :key="buttonBlok._uid" role="presentation">
            <header-button :blok="buttonBlok" role="menuitem" class="h-full" :tabindex="mobileScreen ? -1 : 0" :light="blok.isNh" />
          </li>
        </ol>
        <button
          v-if="blok.links.length > 1"
          id="header-nav-menu-button"
          aria-haspopup="true"
          aria-controls="header-nav-menu"
          aria-label="Menü"
          class="relative inline-flex gap-4 rounded px-3 py-2 text-center font-semibold outline outline-0 outline-offset-2 outline-transparent transition-colors duration-75 desktop-header:hidden"
          :class="'bg-blue text-white hover:outline-blue focus:outline-blue'"
          type="button"
          role="switch"
          :hidden="!mobileScreen"
          :aria-checked="mobileOpen"
          :tabindex="mobileScreen ? 0 : -1"
          @click="mobileOpen = !mobileOpen"
        >
          <span class="text-lg [@media(width<=400px)]:hidden">Menü</span>
          <nuxt-icon class="text-2xl [&>svg]:!mb-0" :class="{ open: mobileOpen }" name="hamburger" />
        </button>
      </template>
    </nav>
    <ol
      v-if="(blok?.links?.length ?? 0) > 1"
      id="header-nav-menu"
      role="menubar"
      aria-orientation="vertical"
      aria-labelledby="header-nav-menu-button"
      :hidden="!mobileScreen"
      :tabindex="mobileScreen && mobileOpen ? 0 : -1"
      class="flex h-[calc(100dvh-7rem)] max-h-0 flex-col place-content-start gap-10 overflow-auto rounded-double px-8 opacity-0 transition-spacing group-aria-expanded:max-h-[calc(100dvh-7rem)] group-aria-expanded:rounded-[0] group-aria-expanded:opacity-100"
      :class="blok?.isNh ? 'bg-green-dark' : 'bg-blue-dark'"
    >
      <li v-for="buttonBlok in blok?.links || []" :key="buttonBlok._uid" class="first:mt-8 last:mb-16" role="presentation">
        <header-button
          :blok="buttonBlok"
          class="!min-h-[3.375rem] w-full justify-center !gap-3 !text-lg"
          role="menuitem"
          :tabindex="mobileScreen && mobileOpen ? 0 : -1"
          :light="blok?.isNh"
          @click="linkClick"
        />
      </li>
    </ol>
  </header>
</template>

<script setup lang="ts">
import type { HeaderStoryblok } from "~/storyblok-component-types"

defineSlots<{ default(): any; }>()
defineProps<{ blok?: HeaderStoryblok | null; }>()

const mobileOpen = ref(false)
const mobileScreen = ref(false)

function onResize() {
  mobileScreen.value = window.innerWidth < 1152
}

watch(mobileOpen, val => {
  // auto-close mobile menu on resize to desktop
  if (!val) mobileOpen.value = false
})

// close mobile menu on un-disabled link click
function linkClick(ev: MouseEvent) {
  const target = ev.target as HTMLElement | null
  if (target?.closest("a")?.dataset.disabled !== "true") mobileOpen.value = false
}

onMounted(() => {
  onResize()
  window.addEventListener("resize", onResize, { passive: true })
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", onResize)
})
</script>

<style lang="scss">
body {
  scroll-padding-top: 8rem;
}

.header-button-list-desktop {
  @media (width<=1440px) and (min-width: theme("screens.desktop")) {
    @apply gap-4;

    a {
      font-size: 1rem !important;
    }
  }
}
</style>
