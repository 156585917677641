import type { ISbEventPayload } from "@storyblok/js"
import { storyblokOptionsKey } from "~/injection-symbols"

export const useSbBridge = (id: number, cb: (event: ISbEventPayload<any>) => void) => {
  const isBridgeLoaded = !import.meta.server && typeof window.storyblokRegisterEvent !== "undefined"
  const storyId = new URL(window.location?.href).searchParams.get("_storyblok") ?? ""
  const inStory = +storyId === id

  const apiOptions = inject(storyblokOptionsKey)

  if (!isBridgeLoaded || !inStory) {
    return
  }

  window.storyblokRegisterEvent(() => {
    const sbBridge = new window.StoryblokBridge({
      resolveRelations: apiOptions?.resolve_relations ?? [],
      resolveLinks: apiOptions?.resolve_links ?? "url",
    })
    sbBridge.on(["input", "published", "change", "unpublished"], event => event?.story?.id === id && cb(event))
  })
}
