import type { ISbStoryData } from "@storyblok/vue"
import consola from "consola"
import { storyblokOptionsKey } from "~/injection-symbols"
import type { FooterStoryblok, HeaderStoryblok } from "~/storyblok-component-types"

export const useSbStory = async (url: string) => {
  const route = useRoute()
  const apiOptions = inject(storyblokOptionsKey)

  const storyblokApiInstance = useStoryblokApi()
  const uniqueKey = `${apiOptions?.cv}-${url}`
  const story = useState<ISbStoryData>(`${uniqueKey}-state`)

  onMounted(() => {
    useSbBridge(story.value.id, event => {
      if (story.value.content.header && typeof event.story?.content.header === "string") {
        event.story.content.header = story.value.content.header
      }
      if (story.value.content.footer && typeof event.story?.content.footer === "string") {
        event.story.content.footer = story.value.content.footer
      }

      story.value = event.story as StoryblokAnyStory

      if (event.action === "published" || event.action === "unpublished") {
        $fetch("/api/storyblok-cache/invalidate", { method: "POST" })
      }
    })
  })

  try {
    consola.start(`Fetching story "${url}"`)
    if (!story.value) {
      const { data, error } = await useAsyncData(uniqueKey, () => {
        consola.info("Using unique key", uniqueKey)
        consola.info("using config", { version: apiOptions?.version, token: apiOptions?.token, cv: apiOptions?.cv })

        return storyblokApiInstance.get(`cdn/stories/${url}`, apiOptions)
      })

      if (error.value) {
        consola.error("Error while fetching story", error.value)
        throw new Error(`Error while fetching story: ${error.value}`)
      } else if (data.value) {
        consola.success("Fetched story", url)
        story.value = data.value?.data.story
      } else {
        if (error) throw new Error("Story not found")
      }
    }

    watch(
      story,
      () => {
        if (route.query.landing !== undefined) {
          if (story.value.content.header?.content) (story.value.content.header.content as HeaderStoryblok).links = []
          if (story.value.content.footer?.content) (story.value.content.footer.content as FooterStoryblok).socmediaList = []
        }
      },
      { immediate: true },
    )

    return story
  } catch {
    throw createError({ statusCode: 404, statusMessage: "Seite nicht gefunden" })
  }
}
