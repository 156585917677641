<template>
  <div class="relative isolate">
    <sb-link
      :id="blok._uid"
      v-editable="blok"
      :link="blok.link"
      class="inline-flex min-h-[2.775rem] items-center gap-2 leading-none peer/header-button text-center justify-center !overflow-visible"
      :class="{ light }"
      :data-never-active="blok.neverActive"
      :data-active="childActive"
      :aria-label="blok.text"
      :aria-controls="`${blok._uid}-submitems`"
      v-bind="$attrs"
    >
      <nuxt-icon v-if="blok.icon" :name="blok.icon.toString()" class="[&>svg]:!h-[1.2em] [&>svg]:!w-[1.2em]" filled />
      <span v-if="blok.text" class="line-clamp-2 text-balance leading-tight" :class="{ 'inline-flex desktop:hidden': blok.iconOnly }">
        <slot>
          <template v-if="blok.text">
            {{ blok.text }}
          </template>
        </slot>
      </span>
    </sb-link>
    <ol
      v-if="(blok.children as HeaderButtonStoryblok[])?.length"
      :id="`${blok._uid}-submitems`"
      :aria-labelledby="blok._uid"
      class="desktop-header:absolute desktop-header:-top-4 desktop-header:-z-10 desktop-header:w-[calc(100%+2rem)] desktop-header:-left-4 rounded-double  desktop-header:peer-hover/header-button:opacity-100 desktop-header:opacity-0 transition-[opacity,max-height] duration-[150ms,300ms] desktop-header:hover:opacity-100 desktop-header:hover:pointer-events-auto desktop-header:peer-hover/header-button:pointer-events-auto desktop-header:pointer-events-none gap-4 flex flex-col desktop-header:max-h-0 desktop-header:hover:max-h-[--max-height] desktop-header:peer-hover/header-button:max-h-[--max-height] desktop-header:overflow-y-hidden px-4 overflow-y-visible"
      :style="{ '--max-height': `${((blok.children as HeaderButtonStoryblok[]).length * 4) + 6 + 1}rem` }"
      :class="light ? 'bg-white' : 'bg-blue-dark'"
    >
      <li v-for="child in (blok.children as HeaderButtonStoryblok[])" :key="child._uid" class="w-full first:pt-6 desktop-header:first:pt-20 desktop-header:last:pb-4 overflow-y-visible">
        <header-button :blok="child" :light="light" class="w-full truncate" />
      </li>
    </ol>
  </div>
</template>

<script setup lang="ts">
import type { HeaderButtonStoryblok, SubHeaderButtonStoryblok } from "~/storyblok-component-types"

const props = defineProps<{ blok: HeaderButtonStoryblok | SubHeaderButtonStoryblok; light?: boolean; }>()
defineSlots<{ default(): any; }>()
defineOptions({ inheritAttrs: false })

const route = useRoute()

const childActive = computed(() =>
  (props.blok.children as SubHeaderButtonStoryblok[])?.some(({ link }) => renderSbLink(link) === route.path),
)
</script>

<style scoped lang="scss">
a {
  @apply relative rounded bg-blue px-3 py-2 text-center text-base font-semibold text-white transition-colors;

  &[data-disabled="true"] {
    position: relative;
    cursor: help;

    &::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      padding: theme("spacing.2") theme("spacing.2");
      font-size: theme("fontSize.sm");
      line-height: 1.2;
      background-color: theme("colors.blue.light");
      border-radius: theme("borderRadius.DEFAULT");
      transform: translateY(100%) translateX(-50%);
      opacity: 0;
      content: "bald verfügbar";
      @apply transition-spacing;
    }

    &:is(:hover, :focus)::after {
      bottom: -0.5rem;
      opacity: 1;
    }
  }

  &:not([data-disabled="true"]) {
    &:hover,
    &:focus,
    &:not([data-never-active="true"]).router-link-active {
      @apply bg-white text-blue-dark;
    }

    &::after {
      content: "";
      @apply absolute -bottom-2 left-1/2 h-1 w-0 -translate-x-1/2 rounded bg-white transition-[width,transform] phone:duration-500 z-10;
    }

    &:not([data-never-active="true"]).router-link-active::after,
    &[data-active="true"]::after {
      @apply w-[calc(100%-.5rem)];
    }

    &:not(.router-link-active):active::after {
      @apply w-8;
    }

    &:not(.router-link-active):hover::after {
      @apply w-2;
    }
  }

  &.light {
    &,
    &::after {
      @apply bg-blue text-white;
    }

    &:not([data-disabled="true"]) {
      &:hover,
      &:focus,
      &:not([data-never-active="true"]).router-link-active,
      &::after {
        @apply bg-blue-dark text-white;
      }
    }
  }
}
</style>
