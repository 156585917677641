<template>
  <div>
    <audoora-header v-if="headerBlok?.content" :blok="headerBlok.content" class="sticky top-0" />
    <main class="container mx-auto flex flex-col gap-4 px-4 py-[min(12rem,20dvh)]" @dblclick="showStack = true">
      <h1 class="text-center text-4xl font-semibold">Fehler {{ error.statusCode }}</h1>
      <h2 class="text-center text-4xl">
        {{ error.statusMessage }}
      </h2>
      <br />
      <transition name="fade-bottom">
        <code v-show="showStack" class="overflow-x-auto whitespace-pre font-mono" v-html="error.stack" />
      </transition>
      <br />
      <nuxt-link to="/" class="button mx-auto w-full tablet:max-w-96"> Zurück zur Startseite </nuxt-link>
    </main>
    <audoora-footer v-if="footerBlok?.content" :blok="footerBlok.content" />
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "nuxt/app"

useHead({ meta: [{ name: "robots", content: "noindex" }] })

defineProps<{ error: NuxtError; }>()

const environment
  = (useRuntimeConfig().public.siteEnv as "production" | "staging" | "development")
  || (process.env.NODE_ENV as "production" | "development")

const [headerBlok, footerBlok] = await Promise.all([
  useSbStory("/global/header"),
  useSbStory("/global/footer"),
])

if (!headerBlok.value) console.error("Error page", "Header not found")
if (!footerBlok.value) console.error("Error page", "Footer not found")

const showStack = ref(environment === "development")
</script>
