import { defineNuxtPlugin } from '#app/nuxt'
import { LazyHeaderButton, LazyImageLinkListElement, LazyLinkListElement, LazyProgressionStep, LazySlideshowSlide, LazyStepsStep, LazyAccordeon, LazyAssetImage, LazyBlogArticleCard, LazyBlogArticleList, LazyBlogSearch, LazyBrandList, LazyCardAccordeon, LazyCardButton, LazyCardFact, LazyCardTeaser, LazyCardText, LazyCtaButton, LazyCtaNewsletter, LazyFormCallBack, LazyFormPdf, LazyFormTinnitus, LazyGrid, LazyHeadline, LazyHeroVideo, LazyHero, LazyHsMeeting, LazyLinkList, LazyProfile, LazyProgression, LazyQuestionAnswer, LazyRibbonBelowPage, LazyRibbon, LazySbTemplate, LazySpacer, LazyStaticVideo, LazyStepList, LazyTeaser, LazyTextTeaser, LazyText, LazyBlogArticleOverview, LazyBlogArticle, LazyMultipageDialog, LazyPage, LazyPageWithTOC, LazySlideshow, LazyNuxtIcon } from '#components'
const lazyGlobalComponents = [
  ["HeaderButton", LazyHeaderButton],
["ImageLinkListElement", LazyImageLinkListElement],
["LinkListElement", LazyLinkListElement],
["ProgressionStep", LazyProgressionStep],
["SlideshowSlide", LazySlideshowSlide],
["StepsStep", LazyStepsStep],
["Accordeon", LazyAccordeon],
["AssetImage", LazyAssetImage],
["BlogArticleCard", LazyBlogArticleCard],
["BlogArticleList", LazyBlogArticleList],
["BlogSearch", LazyBlogSearch],
["BrandList", LazyBrandList],
["CardAccordeon", LazyCardAccordeon],
["CardButton", LazyCardButton],
["CardFact", LazyCardFact],
["CardTeaser", LazyCardTeaser],
["CardText", LazyCardText],
["CtaButton", LazyCtaButton],
["CtaNewsletter", LazyCtaNewsletter],
["FormCallBack", LazyFormCallBack],
["FormPdf", LazyFormPdf],
["FormTinnitus", LazyFormTinnitus],
["Grid", LazyGrid],
["Headline", LazyHeadline],
["HeroVideo", LazyHeroVideo],
["Hero", LazyHero],
["HsMeeting", LazyHsMeeting],
["LinkList", LazyLinkList],
["Profile", LazyProfile],
["Progression", LazyProgression],
["QuestionAnswer", LazyQuestionAnswer],
["RibbonBelowPage", LazyRibbonBelowPage],
["Ribbon", LazyRibbon],
["SbTemplate", LazySbTemplate],
["Spacer", LazySpacer],
["StaticVideo", LazyStaticVideo],
["StepList", LazyStepList],
["Teaser", LazyTeaser],
["TextTeaser", LazyTextTeaser],
["Text", LazyText],
["BlogArticleOverview", LazyBlogArticleOverview],
["BlogArticle", LazyBlogArticle],
["MultipageDialog", LazyMultipageDialog],
["Page", LazyPage],
["PageWithTOC", LazyPageWithTOC],
["Slideshow", LazySlideshow],
["NuxtIcon", LazyNuxtIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
