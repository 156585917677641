<template>
  <button
    v-if="url.startsWith('/dialogs') && openDialog"
    v-bind="isEditor && { title: 'Verlinkung im Editor deaktiviert' }"
    @click="!isEditor && openDialog(url)"
  >
    <slot />
  </button>
  <nuxt-link
    v-else-if="link.linktype === 'story'"
    :data-disabled="!url"
    tabindex="0"
    :to="isEditor ? undefined : { path: url, hash: link.anchor ? `#${link.anchor}` : '' }"
    :target="link.target"
    class="cursor-pointer"
    :class="
      $route.path === url && ($route.hash === `#${link.anchor}` || (!$route.hash && !link.anchor)) ? 'router-link-extra-exact-active' : ''
    "
    v-bind="isEditor && { title: 'Verlinkung im Editor deaktiviert' }"
  >
    <slot />
  </nuxt-link>
  <a
    v-else
    :href="url"
    :target="link.target || '_blank'"
    rel="noopener"
    :data-disabled="!url"
    tabindex="0"
    :to="isEditor ? undefined : url"
    class="cursor-pointer"
    v-bind="isEditor && { title: 'Verlinkung im Editor deaktiviert' }"
  >
    <slot />
  </a>
</template>

<script setup lang="ts">
import type { MultilinkStoryblok } from "~/storyblok-component-types"
import { isEditorKey, openDialogKey } from "~/injection-symbols"

defineSlots<{ default(): any; }>()
const props = defineProps<{ link: MultilinkStoryblok; }>()
const isEditor = inject(isEditorKey, false)

const url = computed<string>(() => renderSbLink(props.link))

const openDialog = inject(openDialogKey, null)

onMounted(() => url.value.startsWith("/dialogs") && openDialog?.(url.value, true))
</script>
