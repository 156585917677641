import type { MultilinkStoryblok } from "~/storyblok-component-types"

export type facebookCommonEvent = {
  AddPaymentInfo: facebook.Pixel.AddPaymentInfoParameters
  AddToCart: facebook.Pixel.AddToCartParameters
  AddToWishlist: facebook.Pixel.AddToWishlistParameters
  CompleteRegistration: facebook.Pixel.CompleteRegistrationParameters
  Contact: facebook.Pixel.LeadParameters
  CustomizeProduct: facebook.Pixel.CustomParameters
  Donate: facebook.Pixel.PurchaseParameters
  FindLocation: facebook.Pixel.ViewContentParameters
  InitiateCheckout: facebook.Pixel.InitiateCheckoutParameters
  Lead: facebook.Pixel.LeadParameters
  Purchase: facebook.Pixel.PurchaseParameters
  Schedule: facebook.Pixel.CustomParameters
  Search: facebook.Pixel.SearchParameters
  StartTrial: facebook.Pixel.CustomParameters
  SubmitApplication: facebook.Pixel.CustomParameters
  Subscribe: facebook.Pixel.CustomParameters
  ViewContent: facebook.Pixel.ViewContentParameters
}

export const hubspotTrack = (action: Hsq.EventNames, options?: unknown) => {
  // @ts-expect-error because _hsq is not defined in the global scope, since declaring window stuff sucks
  window?._hsq?.push([action, options])
}

export const metaTrack = (
  type: "track" | "trackCustom" | "consent",
  event: string,
  params: facebookCommonEvent[keyof facebookCommonEvent] | Record<string, string | number | string[] | number[]> = {},
) => {
  const eventID = new Date().getTime().toString()
  window?.fbq?.(type, event, params, { eventID })

  if (type !== "consent") $fetch("/api/t/meta", { body: { type, event, params, eventID }, method: "POST" })
}

export const googleTagTrack: Gtag.Gtag = (command, ...args) => {
  window?.gtag?.(command, ...args)
}

export const hubspotBanner = (action: Omit<Hsp.EventNames, "addPrivacyConsentListener">) => {
  // @ts-expect-error because _hsp is not defined in the global scope, since declaring window stuff sucks
  window?._hsp?.push([action])
}

export function withConsent(action: (consent: Hsp.consentStatus) => void) {
  // @ts-expect-error because _hsp is not defined in the global scope, since declaring window stuff sucks
  window?._hsp?.push(["addPrivacyConsentListener", action])
}

export function renderSbLink(link?: MultilinkStoryblok) {
  if (!link) return ""
  let url = ""

  if (link.linktype === "story" && link.story) {
    url = ((link.story.url as string) || link.story.full_slug || (link.url as string) || link.cached_url) ?? ""
  } else {
    url = (link.url as string) || (link.cached_url as string)
  }

  if (url && link.linktype !== "url") {
    if (link.linktype === "email") {
      url = `mailto:${url}`
    } else if (url) {
      const urlAsArray = url.split("/")
      if (urlAsArray.at(-1) === "home" || urlAsArray.at(-1) === "index") url = urlAsArray.slice(0, -1).join("/")

      url = `/${url}`
    }
  }

  if (url === "//") url = "/"

  return url || "/"
}
